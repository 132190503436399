
import { WoClassifications } from './index';
import { HrEmployees } from './index';
import { AccCopas } from './index';
import { WoWorkOrderRouting } from './index';
import { FacEquipment } from './index';
import { FacEquipmentGroups } from './index';
import { EngOverAndAboveProjects } from './index';
import { WoOrigins } from './index';
import { QcObservationXworkOrders } from './index';
import { SchResources } from './index';
import { WoRwptemplates } from './index';
import { AllSites } from './index';
import { HrEmployeeJobAssignments } from './index';
import { WoStatuses } from './index';
import { WoTaskingPriority } from './index';
import { NgTenants } from './index';
import { Ttt332 } from './index';
import { TttRbDisposition } from './index';
import { TttTttstatuses } from './index';
import { WoClassificationHistory } from './index';
import { WoEstimates } from './index';
import { WoInventoryParts } from './index';
import { WoLaborEstimates } from './index';
import { WoWorkOrderComment } from './index';
import { WoWorkOrderDetails } from './index';
import { WoWorkOrderTypes } from './index';

export class WoWorkOrders {
   public workOrderId: number = 0;
   public workOrderNumber: string = null;
   public statusId: number = 0;
   public classificationId: number = 0;
   public siteId: number = 0;
   public createdById: number = 0;
   public createdOn: Date = new Date(0);
   public equipmentGroupId: number = 0;
   public equipmentId: number = 0;
   public description: string = null;
   public justification: string = null;
   public specialtyId: number = 0;
   public filePlan: string = null;
   public originId: number = 0;
   public workOrderTypeId: number = 0;
   public workStartDate: Date = new Date(0);
   public dueDate: Date = null;
   public dueHours: number = null;
   public requestorId: number = 0;
   public estimatedLaborHours: number = 0;
   public estimatedMaterialCost: number = null;
   public timecardCode: string = null;
   public currentlyRoutedToId: number = null;
   public requiresLeadApproval: boolean = false;
   public instructions: string = null;
   public rwptemplateId: number = null;
   public lastUpdatedBy: number = 0;
   public completedOnDate: Date = null;
   public completedOnHours: number = null;
   public openApproverId: number = null;
   public openedApprovedDate: Date = null;
   public closeApproverId: number = null;
   public closedApprovedDate: Date = null;
   public lastUpdatedDate: Date = new Date(0);
   public oldId: number = null;
   public closedByEmployeeId: number = null;
   public threeThreeTwo: boolean = null;
   public cancelledOnDate: Date = null;
   public requiresDowntime: boolean = false;
   public tttid: number = null;
   public isEnergyJob: boolean = false;
   public maxAllowedLabor: number = null;
   public copaId: number = null;
   public requiredDataJson: string = null;
   public equipmentDeadlined: boolean = false;
   public tttNumber: string = null;
   public tttStatusId: number = null;
   public tttRequestDate: Date = null;
   public tttPrimaryRequestorId: number = null;
   public tttSecondaryRequestor: string = null;
   public tttSecondaryReqPhone: string = null;
   public tttRequiredDate: Date = null;
   public tttLocation: string = null;
   public tttDonatedResources: string = null;
   public tttDrFunds: boolean = false;
   public tttDrLabor: boolean = false;
   public tttDrMaterial: boolean = false;
   public tttDrContract: boolean = false;
   public tttDrNone: boolean = false;
   public tttUserRemarks: string = null;
   public tttDateCompleted: Date = null;
   public tttPriority: number = null;
   public tttCesworkOrderNum: string = null;
   public tttDateCancelled: Date = null;
   public tttCancelledBy: string = null;
   public tttDateSubmittedToAf: Date = null;
   public tttProblem: string = null;
   public tttRootCause: string = null;
   public tttSecondaryEffects: string = null;
   public tttBuilding: string = null;
   public tttSizeOf: string = null;
   public tttComponentsAffected: string = null;
   public tttKnownHazards: string = null;
   public tttJustificationCont: string = null;
   public tttOther: string = null;
   public oaId: string = null;
   public tenantId: number = 0;
   public resourceId: number = null;
   public taskPriorityId: number = null;
   public tttRbDiscussionDate: Date = null;
   public tttRbDispositionId: number = null;
   public responseDate: Date = null;
   public parentWorkOrderId: number = null;
   public classification: WoClassifications = null;
   public closeApprover: HrEmployees = null;
   public closedByEmployee: HrEmployees = null;
   public copa: AccCopas = null;
   public currentlyRoutedTo: WoWorkOrderRouting = null;
   public equipment: FacEquipment = null;
   public equipmentGroup: FacEquipmentGroups = null;
   public inverseParentWorkOrder: WoWorkOrders[] = [];
   public lastUpdatedByNavigation: HrEmployees = null;
   public oa: EngOverAndAboveProjects = null;
   public openApprover: HrEmployees = null;
   public origin: WoOrigins = null;
   public parentWorkOrder: WoWorkOrders = null;
   public qcObservationXworkOrders: QcObservationXworkOrders[] = [];
   public requestor: HrEmployees = null;
   public resource: SchResources = null;
   public rwptemplate: WoRwptemplates = null;
   public site: AllSites = null;
   public specialty: HrEmployeeJobAssignments = null;
   public status: WoStatuses = null;
   public taskPriority: WoTaskingPriority = null;
   public tenant: NgTenants = null;
   public ttt332: Ttt332[] = [];
   public tttPrimaryRequestor: HrEmployees = null;
   public tttRbDisposition: TttRbDisposition = null;
   public tttStatus: TttTttstatuses = null;
   public woClassificationHistory: WoClassificationHistory[] = [];
   public woEstimates: WoEstimates[] = [];
   public woInventoryParts: WoInventoryParts[] = [];
   public woLaborEstimates: WoLaborEstimates[] = [];
   public woWorkOrderComment: WoWorkOrderComment[] = [];
   public woWorkOrderDetails: WoWorkOrderDetails[] = [];
   public workOrderType: WoWorkOrderTypes = null;
}