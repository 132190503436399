<!-- Navigation -->
<nav id="aims-navbar" class="navbar navbar-default navbar-static-top"
    [ngClass]="{'nav-bar-color':!isDev, 'devColor':isDev}" role="navigation" style="margin-bottom: 0">
    <div class="navbar-header">
        <button type="button" class="navbar-toggle" (click)="isCollapsed.next(!(isCollapsed.value))">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
        <div id="aims-header" class="navbar-brand">
            <div class="navbar-logo">
                <img width="24px" [attr.src]="env.assetsPath + '/asrcfederal-circle-white.png'" />
            </div>
            <div class="navbar-name">
                &nbsp;AIMS
            </div>
        </div>
    </div>
    <!-- /.navbar-header -->

    <ul [ngClass]="{'nav':true, 'navbar-top-links': true, 'navbar-nav':true, 'navbar-collapse':true, 'collapse':isCollapsed | async}" *ngIf="user.value != null">
        <li *ngIf="permissions.canRead(permissions.ComponentID.Facilities)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/assetMgmt">Asset Mgmt</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.ProjectSupport)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/projectSupport">Proj Support</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.Logistics)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" [routerLink]="logisticsLink">Logistics</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.Inventory)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" [routerLink]="inventoryLink">Inventory</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.Scheduling)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/scheduling">Scheduling</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.Timekeeping)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/timekeeping">Timekeeping</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.InformationTechnology)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/it">Info Tech</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.HumanResources)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/hr">HR</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.Management)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/management">Management</a>
        </li>
        <li *ngIf="permissions.canRead(permissions.ComponentID.Config)">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" routerLink="/config">Config</a>
        </li>
        <li *ngIf="sharePointLink !== null">
            <a class="aims-top-links" (click)="isCollapsed.next(true)" href="{{sharePointLink}}">SharePoint</a>
        </li>
    </ul>

    <ul *ngIf="user.value != null"
        [ngClass]="{'nav':true, 'navbar-top-links': true, 'navbar-nav':true, 'navbar-right':true, 'navbar-collapse':true, 'collapse':isCollapsed | async}"
        style="position:relative;right:15px">
        <li style="line-height:20px;padding-left:15px;padding-top:15px;color:red" *ngIf="isDev">
            <b>DEVELOPMENT ENVIRONMENT!!!</b>
        </li>
        <li style="line-height:20px;padding-left:15px;padding-top:15px;color:white" *ngIf="passwordExp != null">
            <b>
                {{passwordExpMessage()}}
            </b>
        </li>
        <li>
            <a id="aims-dd-button" class="aims-top-links" (click)="showDD()">
                <i class="fas fa-user "></i> {{ (user | async)?.loginName }}
            </a>
            <div tabindex="0" #btnDD>
                <ul class="dropdown-menu dropdown-user" role="menu" style="display:block" *ngIf="showUserDD">
                    <li>
                        <a routerLink="/profile" (click)="showDD()"><i class="fas fa-user "></i> My Profile</a>
                    </li>
                    <li>
                        <a routerLink="/profile/passwordReset" (click)="showDD()"><i class="fas fa-unlock-alt"></i> AIMS
                            Password Reset</a>
                    </li>
                    <li *ngIf="permissions.canRead(permissions.ComponentID.MyTraining)">
                        <a routerLink="/hr/myTraining" (click)="showDD()"><i class="fas fa-address-card"></i> View My
                            Training</a>
                    </li>
                    <li>
                        <a routerLink='/reporting/My Schedule/trdp/{"UserID":"{{(user | async).employeeId}}"}'
                            (click)="showDD()"><i class="fas fa-binoculars "></i> View My Schedule</a>
                    </li>
                    <li>
                        <a routerLink="{{this.router.url}}" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Log
                            Off</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a routerLink="/permissions" (click)="showDD()"><i class="fas fa-key"></i> Permissions</a>
                    </li>
                </ul>
            </div>

        </li>
    </ul>
    <!-- /.navbar-top-links -->
    <!-- /.navbar-static-side -->
</nav>
