import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './containers/home/home.component';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home', component: HomeComponent,
    data: {
      title: 'Homepage',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./containers/login/login.module').then(m => m.LoginModule),
    data: {
      title: 'Login',
    }
  },
  {
    path: 'management',
    loadChildren: () => import('./containers/management/management.module').then(m => m.ManagementModule),
    data: {
      title: 'Management',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'assetMgmt',
    loadChildren: () => import('./containers/assetMgmt/assetMgmt.module').then(m => m.AssetMgmtModule),
    data: {
      title: 'AssetMgmt',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'projectSupport',
    loadChildren: () => import('./containers/projectSupport/projectSupport.module').then(m => m.ProjectSupportModule),
    data: {
      title: 'Project Support',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'logistics',
    loadChildren: () => import('./containers/logistics/logistics.module').then(m => m.LogisticsModule),
    data: {
      title: 'Logistics',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'scheduling',
    loadChildren: () => import('./containers/scheduling/scheduling.module').then(m => m.SchedulingModule),
    data: {
      title: 'Scheduling',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'timekeeping',
    loadChildren: () => import('./containers/timekeeping/timekeeping.module').then(m => m.TimekeepingModule),
    data: {
      title: 'Timekeeping',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'it',
    loadChildren: () => import('./containers/it/it.module').then(m => m.ITModule),
    data: {
      title: 'IT',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('./containers/reporting/reporting.module').then(m => m.ReportingModule),
    data: {
      title: 'Reporting',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'hr',
    loadChildren: () => import('./containers/hr/hr.module').then(m => m.HRModule),
    data: {
      title: 'HR',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'permissions',
    loadChildren: () => import('./containers/permissions/permissions.module').then(m => m.PermissionsModule),
    data: {
      title: 'Permissions',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./containers/profile/profile.module').then(m => m.ProfileModule),
    data: {
      title: 'User Profile',
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('./containers/reporting/reporting.module').then(m => m.ReportingModule),
    data: {
      title: 'Reporting',
    },
    canActivate: [AuthGuard],

  },
  { path: 'config',
    loadChildren: () => import('./containers/config/config.module').then(m => m.ConfigModule),
    data: {
      title: 'Config'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./containers/inventory/inventory.module').then(m => m.InventoryModule),
    data: {
      title: 'Inventory',
    },
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
