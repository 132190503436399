
import { HrEmployees } from './index';
import { HrPsc } from './index';
import { HrTitleXJobAssignment } from './index';
import { HrJobTitles } from './index';
import { NgTenants } from './index';
import { TrQualXJobAssignments } from './index';
import { WoRwptemplates } from './index';
import { WoWorkOrders } from './index';

export class HrEmployeeJobAssignments {
   public employeeJobAssignmentId: number = 0;
   public employeeJobAssignmentCode: string = null;
   public assignmentDescription: string = null;
   public workOrderDropDown: boolean = null;
   public jobTitleId: number = null;
   public active: boolean = null;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public hrPsc: HrPsc[] = [];
   public hrTitleXJobAssignment: HrTitleXJobAssignment[] = [];
   public jobTitle: HrJobTitles = null;
   public tenant: NgTenants = null;
   public trQualXJobAssignments: TrQualXJobAssignments[] = [];
   public woRwptemplates: WoRwptemplates[] = [];
   public woWorkOrders: WoWorkOrders[] = [];
}