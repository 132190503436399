
import { HrEmployees } from './index';
import { WoRwpstatus } from './index';
import { WoRwptemplateTypes } from './index';
import { NgTenants } from './index';
import { WoClassifications } from './index';
import { WoStatuses } from './index';
import { WoRwpcomment } from './index';
import { WoRwppartsEstimate } from './index';
import { WoRwptemplateEquipmentXs } from './index';
import { HrEmployeeJobAssignments } from './index';
import { WoTaskingPriority } from './index';
import { WoWorkOrders } from './index';

export class WoRwptemplates {
   public rwptemplateId: number = 0;
   public rwpstatusId: number = 0;
   public rwptemplateTypeId: number = 0;
   public shadowedByRwptemplateId: number = null;
   public generateXdaysBeforeDue: number = 0;
   public generateXhoursBeforeDue: number = 0;
   public monthsBetween: number = 0;
   public daysBetween: number = 0;
   public meterHoursBetween: number = 0;
   public woRequiresLeadApproval: boolean = false;
   public woEstimatedLaborHours: number = 0;
   public woDescription: string = null;
   public woClassificationId: number = 0;
   public woFilePlanRequired: boolean = false;
   public woSpecialtyId: number = null;
   public woInstructions: string = null;
   public woRequiresDowntime: boolean = false;
   public woDefaultStatusId: number = 0;
   public woEstMatCost: number = null;
   public createdById: number = 0;
   public lastUpdatedById: number = 0;
   public createdOn: Date = new Date(0);
   public lastUpdatedDateTime: Date = new Date(0);
   public oldId: number = null;
   public oldShadowedById: number = null;
   public woRequiredDataJson: string = null;
   public tenantId: number = 0;
   public woTaskPriorityId: number = null;
   public createdBy: HrEmployees = null;
   public inverseShadowedByRwptemplate: WoRwptemplates[] = [];
   public lastUpdatedBy: HrEmployees = null;
   public rwpstatus: WoRwpstatus = null;
   public rwptemplateType: WoRwptemplateTypes = null;
   public shadowedByRwptemplate: WoRwptemplates = null;
   public tenant: NgTenants = null;
   public woClassification: WoClassifications = null;
   public woDefaultStatus: WoStatuses = null;
   public woRwpcomment: WoRwpcomment[] = [];
   public woRwppartsEstimate: WoRwppartsEstimate[] = [];
   public woRwptemplateEquipmentXs: WoRwptemplateEquipmentXs[] = [];
   public woSpecialty: HrEmployeeJobAssignments = null;
   public woTaskPriority: WoTaskingPriority = null;
   public woWorkOrders: WoWorkOrders[] = [];
}