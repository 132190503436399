export const environment = {
    debug: 'true',
    name: 'qa00',
    production: 'false',
    apiUrl: 'https://qa00-aims-api.azurewebsites.us/api',
    hubsUrl: 'https://qa00-aims-api.azurewebsites.us',
    reportsHost: 'https://qa00-aims-reports.azurewebsites.us',
    reportsUrl: 'https://qa00-aims-reports.azurewebsites.us/api/Reports',
    assetsPath: 'https://qa00.aimshq.com/assets'
};
