import { MatTableDataSource } from '@angular/material/table'
import Utils from '../shared/utils'

export class AimsCommonTableDataSource<T> extends MatTableDataSource<T> {
  filterValues = {}
  filterSelectObj = []
  filterAndSortFunctions: { [key: string]: Function }

  constructor(data: T[]) {
    super(data)

    // Overrride default filter behaviour of Material Datatable
    this.filterPredicate = this.createFilter()
    this.sortingDataAccessor = this.aimsSortingDataAccessor
  }

  public setupDynamicFiltersAndSort(filterAndSortFunctions) {
    this.filterAndSortFunctions = filterAndSortFunctions
  }

  aimsSortingDataAccessor(data: T, sortHeaderId: string): any {
    const customData = this.getDataCustom(data, sortHeaderId)
    switch (sortHeaderId) {
      case 'date': {
        return new Date(data[sortHeaderId])
      }
      default: {
        if (customData) {
          return customData
        }
        if (sortHeaderId.indexOf('.') > -1) {
          return Utils.getValue(data, sortHeaderId)
        }
        return data[sortHeaderId]
      }
    }
  }

  getDataCustom(data, col) {
    const fn = this.filterAndSortFunctions[col]
    if (fn && fn[0]) {
      return fn[0](data)
    }
    return undefined
  }

  getCompareCustom(term, data, col) {
    const fn = this.filterAndSortFunctions[col]
    if (fn && fn[1]) {
      return fn[1](term, data)
    }
    return undefined
  }

  // This came from inspiration here:
  // https://github.com/ipsjolly/FreakyJolly.com/blob/master/Demo/Angular/ng-material-table-multi-filter-demo/src/app/app.component.html
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter)
      let isFilterSet = false
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true
        } else {
          delete searchTerms[col]
        }
      }

      let nameSearch = () => {
        let found = new Array()
        if (isFilterSet) {
          for (const col in searchTerms) {
            var terms =
              typeof searchTerms[col] === 'string'
                ? searchTerms[col].trim().toLowerCase().split(' ')
                : [searchTerms[col]]
            terms.forEach((word) => {
              let dataCol = this.getDataCustom(data, col) // Check for dynamic filter first
              let customCompare = this.getCompareCustom(word, data, col)
              if (customCompare !== undefined) {
                if (customCompare) {
                  found.push(true)
                } else {
                  found.push(false)
                }
                return // No need to check further
              }
              if (dataCol === undefined) {
                dataCol = data[col] || Utils.getValue(data, col) // Then default filters to get data
              }
              if (
                dataCol !== undefined &&
                dataCol !== null &&
                dataCol.toString().toLowerCase().indexOf(word) !== -1 &&
                isFilterSet
              ) {
                found.push(true)
              } else if (word.value && isFilterSet) {
                // look for date object attribute
                // Maybe in the future we make all filters an object and then it has a type
                // on it then we can switch on that for filtering
                found.push(Utils.compareDate(dataCol, searchTerms[col]))
              } else {
                found.push(false)
              }
            })
          }
          return found.every(function (e) {
            return e === true
          })
        } else {
          return true
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  filterChange(value, key) {
    this.filterValues[key] = typeof value === 'string' ? value.trim().toLowerCase() : value
    this.filter = JSON.stringify(this.filterValues)
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined
    })
    this.filter = ''
  }
}
