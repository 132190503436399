
import { WoRwptemplates } from './index';
import { NgTenants } from './index';

export class WoRwppartsEstimate {
   public rwppartsEstimateId: number = 0;
   public ui: string = null;
   public quantity: number = 0;
   public nomenclature: string = null;
   public manufacturer: string = null;
   public partNumber: string = null;
   public cost: number = 0;
   public rwptemplateId: number = 0;
   public tenantId: number = 0;
   public rwptemplate: WoRwptemplates = null;
   public tenant: NgTenants = null;
}