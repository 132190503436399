export enum ComponentEnum {
	Logistics = 1,
	AdvancedSearch = 2,
	BuyersBuyQueue = 3,
	BuyersQuoteQueue = 4,
	LogisticsDashboard = 5,
	MaterialCoordinatorQueue = 6,
	Requisition = 7,
	ShoppingCart = 8,
	Step1ApprovalQueue = 9,
	Step2ApprovalQueue = 10,
	ValidatorQueue = 11,
	Permissions = 12,
	PermissionsDashboard = 13,
	UserPermissions = 14,
	GroupPermissions = 15,
	ComponentRegistration = 18,
	PermissionsReport = 19,
	LogisticsConfig = 20,
	CatalogMaintinance = 21,
	AdditionalValidation = 22,
	HumanResources = 23,
	InformationTechnology = 24,
	HumanResourcesDashboard = 25,
	Employee = 26,
	Reporting = 27,
	ViewEmployees = 28,
	PrintShippingLabel = 29,
	HumanResourcesConfig = 30,
	ViewInboundNotices = 32,
	InboundNotice = 33,
	ShippingRequest = 34,
	PSC = 35,
	PscWsLeadReviewQueue = 36,
	PscDmApprovalQueue = 37,
	PscPmmApprovalQueue = 38,
	PscHrmApprovalQueue = 39,
	PscAcctReceivalQueue = 40,
	PscItReceivalQueue = 41,
	PscHrRoutingQueue = 42,
	AwaitingShipping = 43,
	ViewShippingRequests = 44,
	PscHrRejectedQueue = 45,
	PscHrmRejectedQueue = 46,
	ViewCatalog = 47,
	ViewBins = 48,
	MasterBins = 49,
	ReceivingHistory = 50,
	PscHrAcceptedQueue = 51,
	PscHrmAcceptedQueue = 52,
	PscAcctReceivedQueue = 53,
	Engineering = 56,
	Facilities = 57,
	Scheduling = 58,
	Timekeeping = 59,
	IT = 60,
	ManageMacs = 61,
	Management = 62,
	ManagementDashboard = 63,
	ReportingTraining = 64,
	EmployeeTraining = 65,
	MyTraining = 66,
	TrainingConfig = 67,
	ViewDrawings = 68,
	View332s = 69,
	Drawing = 70,
	ThreeThreeTwo = 71,
	SelfTrainedCert = 72,
	TrainerCert = 73,
	QualApproval = 74,
	Timecard = 75,
	TimecardApproval = 76,
	TimecardReview = 77,
	TimecardAccept = 78,
	TimecardManage = 79,
	TimecardHR = 80,
	TaskCodes = 81,
	AbsenceCodes = 82,
	PscGenerateFiscalPay = 83,
	ViewWorkOrders = 84,
	FacilitiesDashboard = 85,
	WorkOrderSearch = 86,
	ClosedWorkOrders = 87,
	ApproveWorkOrders = 88,
	WorkOrder = 89,
	RWP = 90,
	ViewRWPTemplates = 91,
	RWPSearch = 92,
	Scheduler = 93,
	SchedulingConfig = 94,
	SAN = 95,
	Schedule = 96,
	ScheduleApproval = 97,
	ProjectSupport = 98,
	ViewCARs = 99,
	EditWasteDrums = 100,
	TaskingPriority = 101,
	Meters = 102,
	ForceCloseReq = 103,
	EnvironmentalConfig = 104,
	Fuels = 105,
	UnlockFuel = 106,
	FuelConfig = 107,
	TravelRequest = 108,
	TravelStep1Approval = 109,
	TravelDashboard = 110,
	TravelOrphaned = 111,
	TravelOpenended = 112,
	TravelAwaiting = 113,
	TravelStep2Approval = 114,
	SchedulerActionRequired = 115,
	Equipment = 116,
	MeterReadings = 117,
	OverAndAboveProject = 119,
	EngineeringConfig = 120,
	FirearmApproval = 121,
	TravelUnconfirmed = 122,
	SendTravelEmails = 123,
	SchedulerFunction = 124,
	QualityControlConfig = 125,
	QualityControl = 126,
	CARs = 127,
	CDIs = 128,
	QcChecklists = 129,
	CarFinalApproval = 130,
	ReportSchedules = 131,
	Observations = 132,
	AIMSUsers = 133,
	InspectionReports = 134,
	Config = 135,
	WorkOrderConfig = 136,
	StatusReportsConfig = 137,
	WaterLandfillPower = 138,
	DegreeDays = 139,
	DeadlinedEquipment = 140,
	EditWaterLandfillPower = 141,
	ManometerReadings = 142,
	PowerPlantOil = 143,
	RefrigerantCylinders = 144,
	LandfillStatus = 145,
	WeeklyStatus = 146,
	PCAReview = 147,
	WasteDrumStatus = 148,
    AssetConfig = 149,
	CloneGroup = 152,
    CloneUserPermissions = 153,
    Inventory = 154,
    WSRUnlock = 155,
    WorkOrderDetails = 156,
    Traveler = 157,
    InventoryConfig = 158,
    InventoryReceipt = 159,
    InventoryAdjust = 160,
    InventoryExpend = 161,
    ViewInventory = 162,
    ExternalReview = 163,
    ViewReceivable = 164,
    ComponentReport = 165,
    ClaimReq = 166,
    WorkOrderDocuments = 167,
	SupplyQueue = 168
}
