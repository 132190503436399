
import { WoRwptemplates } from './index';

export class WoRwpcomment {
   public rwpcommentId: number = 0;
   public rwptemplateId: number = 0;
   public commentDate: Date = new Date(0);
   public comment: string = null;
   public addedBy: string = null;
   public addedById: number = null;
   public attachmentGuid: string = null;
   public attachmentName: string = null;
   public attachmentType: string = null;
   public tenantId: number = 0;
   public rwptemplate: WoRwptemplates = null;
}