
import { HrEmployees } from './index';
import { NgTenants } from './index';

export class AccTimeEntryCodes {
   public taskId: number = 0;
   public taskCode: string = null;
   public taskDescription: string = null;
   public clin: string = null;
   public workType: string = null;
   public contractYear: string = null;
   public abbrCode: string = null;
   public maxValidDate: Date = null;
   public assignedDate: Date = null;
   public sites: string = null;
   public jobTitles: string = null;
   public projectCode: string = null;
   public copa: string = null;
   public tenantId: number = 0;
   public hrEmployees: HrEmployees[] = [];
   public tenant: NgTenants = null;
}